import React from 'react';
import './ComingSoon.css';
import backgroundImage from '../assets/food-background.jpg';
import logoImage from '../assets/logo.png'; 
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const ComingSoon = () => {
    return (
        <div
            className="coming-soon-container"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="logo-container">
                <img src={logoImage} alt="Logo" className="logo" />
            </div>
            <div className="mt-5 d-flex justify-content-center flex-column align-items-center hero">
                <h1 className="title mt-5">We're Cooking Something Delicious!</h1>
                <p className="description">Our website is coming soon. Stay tuned for amazing food!</p>
                <div className="time">Coming Soon...</div>
                <div className="contact-info">
                    <div className="social-links">
                        <a href="https://www.instagram.com/foodies_choice_uk/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram style={{ fontSize: '24px'}}/>
                        </a>
                        <a href="https://www.facebook.com/share/19YggiiKaZ/" target="_blank" rel="noopener noreferrer">
                           <FaFacebook style={{ fontSize: '24px' }}/>
                        </a>
                    </div>
                    <div className="contact-number">
                        <p>Contact us: <a href="tel:+44 79660075440">+44 79660075440</a></p>
                    </div>
                    <div className="address">
                        <p>Visit us at: 18 Watford Road, Wembley, Brent, United Kingdom HA0 3EP</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;

