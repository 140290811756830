import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ComingSoon from './Components/comingSoon'; 

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<ComingSoon />} />
            </Routes>
        </Router>
    );
};

export default App;

